import { Theme, createStyles } from '@material-ui/core/styles';
/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const style = (theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
			width: '100vw',
			height: '100vh',
			overflow: 'hidden',
			display: 'flex',
			background: 'url(/static/images/background.webp) no-repeat center center fixed',
			backgroundSize: 'cover',
			flexDirection: 'column',
			transition: theme.transitions.create(['margin'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			}),
			'@media print': {
				height: '100%',
				width: '100%',
				background: 'none'
			}
		},
		rootBackground: {
			'&::after': {
				content: '" "',
				opacity: 0.6,
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				position: 'absolute',
				backgroundSize: 'cover',
				zIndex: -1
			}
		},
		container: {
			position: 'relative',
			height: 'calc(100vh - 56px)',
			display: 'flex',
			transition: theme.transitions.create(['margin'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			}),
			'@media print': {
				height: '100%',
				maxHeight: '100%'
			}
		},
		header: {
			zIndex: 1111,
			'@media print': {
				display: 'none'
			}
		},
		backdrop: {
			position: 'fixed',
			top: 50,
			left: 240,
			zIndex: 9999,
			width: '100%',
			height: 'calc(100vh - 56px)'
		},
		menuDrag: {
			position: 'absolute',
			left: 0,
			top: '50px',
			width: 36,
			color: 'white',
			height: '100vh',
			zIndex: 2,
			cursor: 'col-resize',
			display: 'none',
			justifyContent: 'center',
			alignItems: 'center'
		},
		menuDragLeft: {
			width: 16
		},
		menuDragHide: {
			display: 'none'
		},
		menuDragButton: {
			marginLeft: -20,
			display: 'none'
		},
		menuDragIcon: {
			color: 'white'
		},
		drawerActive: {}
	});

export default style;
