import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			flex: 1,
			overflowX: 'hidden',
			overflow: 'auto',
			maxHeight: 'calc(100vh - 56px)',
			display: 'flex',
			flexDirection: 'column',
			'@media print': {
				height: '100%',
				maxHeight: '100%'
			}
		},
		styledContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
			'& > *': {
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
			}
		}
	});

export default Styles;
