import * as React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { withRouter, RouteComponentProps } from 'react-router';
import { WithStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import { Box, IconButton } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Swipeable } from 'react-swipeable';
import HeaderMenu from '../../../components/HeaderMenu';
import { RootState } from '../../../store';
import Style from './style';
import { MainState } from '../../../redux/Root/types';
import { ChangeTitle, ToggleDrawer } from '../../../redux/Root/actions';
import Routes from '../Routes';
import { generateStyle } from '../../../utils/functions';

interface Props extends RouteComponentProps, WithStyles<typeof Style> {
	changetitle: typeof ChangeTitle;
	toggleDrawer: typeof ToggleDrawer;
	main: MainState;
	// pass
}

/**
 *
 */
export class Main extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {void} Jsx.
	 */
	componentDidMount = () => {
		const { toggleDrawer, history } = this.props;
		this.hideLoading();
		toggleDrawer(false);
		const dRedirect = localStorage.getItem('d-redirect');
		if (dRedirect) {
			localStorage.removeItem('d-redirect');
			history.push(dRedirect);
		}
	};

	/**
	 * OnFooter Change
	 * @param {any} item - Properties.
	 * @returns {void}
	 */
	onMenuClick = (item: string) => {
		const { history } = this.props;
		switch (item) {
			case 'all_apps': {
				history.replace('/');
				break;
			}
			case 'settings': {
				history.push('/settings');
				break;
			}
			case 'speak_up':
				break;

			default:
				break;
		}
		this.handleDrawerClose();
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	hideLoading = () => {
		// pass
	};

	/**
	 * Component did Mount.
	 * @param {any} item - Properties.
	 * @returns {void}
	 */
	handleBack = () => {
		const { history } = this.props;
		history.replace('/');
	};

	/**
	 * Component did Mount.
	 * @param {any} item - Properties.
	 * @returns {void}
	 */
	handleLogout = async () => {};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	handleDrawerOpen = () => {
		const { classes, toggleDrawer } = this.props;
		const body = document.querySelector(`.${classes.container}`) as HTMLElement;
		if (body) {
			body.style.marginLeft = '240px';
		}
		toggleDrawer(true);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	handleDrawerClose = () => {
		const { classes, toggleDrawer } = this.props;
		const body = document.querySelector(`.${classes.container}`) as HTMLElement;
		if (body) {
			body.style.marginLeft = '0px';
			body.style.pointerEvents = 'auto';
		}
		toggleDrawer(false);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	renderHeaderMenu = () => {
		const { main } = this.props;
		const { title = '', meta = '' } = main;
		return (
			// <HeaderMenuNew
			// 	menuRight={
			// 		meta !== '' ? (
			// 			<strong style={{ color: 'black', fontSize: 18, marginRight: 8 }}>
			// 				{meta}
			// 			</strong>
			// 		) : null
			// 	}
			// />
			<HeaderMenu
				menuRight={
					meta !== '' ? (
						<strong style={{ color: 'black', fontSize: 18, marginRight: 8 }}>
							{meta}
						</strong>
					) : null
				}
				showDrawerClose
				showBack={false}
				showSearch={false}
				elevation={1}
				onLogoClick={this.handleBack}
				menuTitle={!isEmpty(title) ? title : ''}
				onLogout={this.handleLogout}
				menu
				onMenuClick={this.onMenuClick}
				onBack={this.handleBack}
				onDrawerOpen={this.handleDrawerOpen}
				onDrawerClose={this.handleDrawerClose}
				id="header"
			/>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render = () => {
		const { classes, main, location } = this.props;
		const { drawer } = main;
		return (
			<div
				className={clsx(classes.root, classes.rootBackground, {
					[classes.drawerActive]: drawer
				})}
			>
				<Box className={classes.header}>{this.renderHeaderMenu()}</Box>
				<Box className={classes.container}>
					<Routes />
				</Box>
				<Swipeable
					onSwipedRight={this.handleDrawerOpen}
					trackMouse
					trackTouch
					preventDefaultTouchmoveEvent
					delta={0}
				>
					<div
						role="presentation"
						className={clsx(classes.menuDrag, {
							[classes.menuDragHide]: drawer,
							[classes.menuDragLeft]: location.pathname !== '/'
						})}
						onClick={this.handleDrawerOpen}
					>
						{location.pathname === '/' && (
							<IconButton
								title="Click/Drag to open menu"
								onClick={this.handleDrawerOpen}
								className={classes.menuDragButton}
							>
								<KeyboardArrowRight className={classes.menuDragIcon} />
							</IconButton>
						)}
					</div>
				</Swipeable>
				<div
					className={clsx({ [classes.backdrop]: drawer })}
					role="presentation"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.handleDrawerClose();
						return false;
					}}
				/>
			</div>
		);
	};
}
/**
 * Map state to props
 * @param {any} state Global state Object
 * @returns {Object} State to pros.
 */
const mapStateToProps = (state: RootState) => ({
	main: state.main
	// ARConfig: state.applications.ARConfigInfo,
});

const mapDispatchToProps = {
	changetitle: ChangeTitle,
	toggleDrawer: ToggleDrawer
};

export default generateStyle(
	Style,
	'MDFRoot'
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Main)));
