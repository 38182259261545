import {
	CHANGETITLE,
	ChangeTitleAction,
	TOGGLEDRAWER,
	ToggleDrawerAction,
	ChangeTitlePayload
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ChangeTitle = (payload: ChangeTitlePayload, meta?: any): ChangeTitleAction => {
	return {
		type: CHANGETITLE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ToggleDrawer = (payload: boolean, meta?: any): ToggleDrawerAction => {
	return {
		type: TOGGLEDRAWER,
		payload,
		meta
	};
};
