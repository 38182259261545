import * as React from 'react';
import { connect } from 'react-redux';
import Loadable from '@loadable/component';
import { WithStyles, Box } from '@material-ui/core';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthEvents } from '../../../events';
import { APPLICATIONEVENTS } from '../../../constants/events';
import { RootState } from '../../../store';
import PageContainer from '../../../components/PageContainer';
import withUser, { WithUserProps } from '../../../components/Login/consumer';

import Style from './style';
import { generateStyle } from '../../../utils/functions';

const Dashboard = Loadable(() => import('../Dashboard'));

const Child = Loadable(() => import('../../Child'));

const NoMatch = Loadable(() => import('../../NoMatch'));

interface Props extends WithStyles<typeof Style>, WithUserProps, RouteComponentProps {
	// pass
}
/**
 *
 */
class Routes extends React.PureComponent<Props> {
	componentDidMount = () => {
		AuthEvents.on(APPLICATIONEVENTS.TOKENCHANGED, (e) => {
			// console.log('Refresh Token changed', e);
		});
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render = () => {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<Box className={classes.styledContainer}>
					<Switch>
						<Route path="/" exact>
							<PageContainer>
								<Dashboard />
							</PageContainer>
						</Route>
						<Route path="/app/:id">
							<Child />
						</Route>
						<Route path="*">
							<NoMatch />
						</Route>
					</Switch>
				</Box>
			</div>
		);
	};
}

/**
 * Map state to props
 * @param {any} state Global state Object
 * @returns {Object} State to pros.
 */
const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default generateStyle(
	Style,
	'MDFRoutes'
)(withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(Routes))));
